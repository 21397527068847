export const webArchive = [
    {
      version: "Version 1: 2018",
      cover: "./website-archive/v1_cover.gif",
      alt1: "./website-archive/v1_alt-1.webp",
      alt2: "./website-archive/v1_alt-2.webp",
      content: `In 2018, I had just graduated from Wesleyan as an <a href="https://en.wikipedia.org/wiki/American_studies" target="_blank">American Studies</a> major with a completely made up concentration I dubbed “Cultural Studies of Art” (my transcript includes utopian societies, experimental music, new media art, IP law, public infrastructure, theories of friendship, and other sincerely enriching topics that render my degree incoherent to most people). I had a work study job in the Marketing department at <a href="https://www.weslpress.org" target="_blank">Wesleyan University Press</a> throughout college, so my only post-grad job prospects were book publishing, writing, or completely conning my way into something I was unqualified for. In my senior spring semester, I took a “creative code” class that included an intro to <a href="https://processing.org/" target="_blank">Processing</a>, and I was flown to California for a final round of technical writing interviews at Google that I didn’t get (thankfully, I think), but all of this made tech seem, weirdly, viable.<br/><br/>
      
      I wound up getting hired as an Office Manager at a tiny NYC tech start-up where my friend worked. My responsibilities were coordinating meetings and ordering snacks from Amazon Fresh. It was slow and boring and I hated it. It made me existential in the annoying way that 22-year-olds get existential about having a normal job. But I was at an office every day with early-20s software engineers who clowned around, and I figured I should at least capitalize on that and learn how to build a website.<br/><br/>
      
      During lunches and slow afternoons, I worked through the <a href="https://www.edx.org/cs50" target="_blank">Harvard CS50 Computer Science course</a> on EdX.  I starting inspecting personal websites I found on <a href="http://are.na" target="_blank">Are.na</a> in the browser. I found one that had used the CSS library <a href="https://tachyons.io/" target="_blank">Tachyons</a> and another that used <a href="https://github.com/mattboldt/typed.js/" target="_blank">Typed.js</a> to animate the text in their bio. Most of the code I used in my own website was copied and pasted from other people’s websites or a library. The image galleries in this version of my website use copied and pasted CSS from <a href="https://tachyons.io/components/collections/posters/index.html" target="_blank">this Tachyons page component</a>. My coworker helped me with DNS pointing one afternoon and my hand-coded website was officially deployed.<br/><br/>
      
      At the time, I identified mostly as a digital artist who did some freelance writing. My illustration work was rooted in abstract comics and experimental 3D modeling, and I was interested in getting residencies and publication opportunities. I had literally no idea what jobs I would have in the future, but I had creative momentum here, and saw it as my long-term prospect. This version of my website tried to plant that seed.`,
      intention: "Keep the design simple but clearly custom, Highlight my visual art and writing without pigeon-holing myself in either",
      milestone: "Using media queries, Using Git in the terminal, Using a CSS library, Injecting a JS script into my HTML body,Hosted on GoDaddy",
      favorite: `My “logo,” which was just a nested div with a hover state`
    },

    {
      version: "Version 2: 2019 - Early 2020",
      cover: "./website-archive/v2_cover.webp",
      alt1: "./website-archive/v2_alt-1.webp",
      alt2: "./website-archive/v2_alt-2.webp",
      content: "At work, I had swindled my way from a title of Office Manager to a title of UI/UX Designer, which is the kind of rogue maneuver you can get away with at a small start-up by volunteering to do design work until you have enough project experience to make a genuine case for this kind of promotion (I later learned that a company calling a role “UI/UX Designer” is a design industry red flag, because it implies no one at the company understands either – but neither did I at the time). At this point I still felt mostly like an artist, and was getting a lot of validation for my artwork, but if felt forced and, as I started having wrist problems, physically impossible. This iteration still feels like an “artist’s website,” but I was losing bandwidth to focus on anything but improving my job situation, which was disproportionately weighing on my day-to-day emotional state, so I would soon channel more energy to constructing myself as a web designer.",
      intention: "Create a denser display of information on the homepage, Give individual projects more room to breathe on their own pages, Iterate on a visual “brand” I had started developing using this color palette",
      milestone: "First multi-page website, More dynamic use of Tachyons page layouts",
      favorite: "My doodle-y “headshot,” aka self-timer iPhone photo in a Berlin Airbnb"
    },

    {
        version: "Version 3: Early 2020",
        cover: "./website-archive/v3_cover.webp",
        alt1: "./website-archive/v3_alt-1.webp",
        alt2: "./website-archive/v4_alt-1.webp",
        content: `I left that first job in March 2020, and entered a 15-month-long freelancing stint and job hunt that required me to start explicitly pivoting my self-presentation away from visual art towards design. I didn’t have much design work to show for myself, so this iteration was my first attempt at treating my personal website as web-design portfolio piece itself. <br/><br/>

        I was also beginning to feel self-conscious about a lack of cohesion in my visual work, and frankly didn’t like a lot of it. It was early quarantine, and I was working my way through the <a href="https://www.superhi.com/courses/javascript-for-designers" target="_blank">SuperHi JavaScript for Designers</a> course, and I purposefully nested all images of my work so I wouldn’t have to necessarily design in conversation with them.`,
        intention: "Infuse more personality into the design, Contain all info on one page with minimal scrolling, Create a more personal tone in my bio, Hide my visual work on the site’s first impression",
        milestone: "Writing JS from scratch, Building a split screen page layout with independent scroll behavior, Using default CSS colors",
        favorite: "I used only default CSS colors in this entire design (tomato, snow, lightcyan, and springgreen), and cited them all like a footnote"
      },

      {
        version: "Version 4: Mid 2020",
        cover: "./website-archive/v4_cover.webp",
        alt1: "",
        alt2: "",
        content: `At this point, I was feeling increasingly detached from my identity as a visual artist, but felt like I had very little else to show for myself. I now had intense chronic pain in my wrists that made it difficult to hold anything in my hands, let alone keep up a digital art practice while also doing computer-based work to support myself. I was indecisive and lacking confidence. A text-forward site felt like a safe-haven for my identity crisis.`,
        intention: "Deemphasize myself as a visual artist, Make myself seem as interesting and competent as possible without referencing actual projects or work experience...",
        milestone: "None, But my older sister ripped the source code for her own website without telling me which gave my inner child intense satisfaction",
        favorite: "The custom multi-colored bullet points in the bio"
      },

      {
        version: "Version 5: Late 2020",
        cover: "./website-archive/v5_cover.gif",
        alt1: "./website-archive/v5_alt-1.webp",
        alt2: "./website-archive/v5_alt-2.webp",
        content: `I had accumulated more freelance work as a web designer, but my design instincts continued to be informed by my interest in sequential art and abstract comics. I wanted to create a panel-based layout that housed a range of content that varied in form and utility, kind of like a website dollhouse. I remember being very inspired by <a href="https://nathan.tokyo/" target="_blank">Nathan Taylor's portfolio</a>, which I found on Are.na, but was unable to match, and slightly uninterested in matching, the fidelity of Nathan’s site.`,
        intention: "Coherently distinguish between my design practice and art practice in a single portfolio, Make my website feel like a funhouse",
        milestone: "More advanced FlexBox layouts but still using Tachyons",
        favorite: "I hid a dream journal as an easter egg on this site"
      },

      {
        version: "Version 6: 2021",
        cover: "./website-archive/v6_cover.gif",
        alt1: "./website-archive/v6_alt1.webp",
        alt2: "./website-archive/v6_alt-2.webp",
        content: `My job hunt was becoming desperate, and I recognized a need to make my website more straightforward without losing its personality. I reverted to a more conventional layout and brought visual work to the forefront, but kept the general styling and type treatment as the previous version. <br/></br>

        This is my favorite iteration of my website besides my current one – it felt true to me and my work and less like I was trying to recreate things I saw. It definitely didn’t look like a conventional design portfolio, and I took a lot of pride in that. I started to notice people adding my website to design moodboards on Are.na, and even found someone who ripped my style sheet and credited me on their own website. It’s the small victories...<br/><br/>
        
        This was my website when I got my first full-time product design job.`,
        intention: "Find a job once and for all!!",
        milestone: "First time feeling like my website came from me and not from an assemblage of other people's designs",
        favorite: "I really love this type treatment and think it was impactful without being overly trendy"
      },

      {
        version: "Version 7: Late 2021",
        cover: "./website-archive/v7_cover.gif",
        alt1: "",
        alt2: "",
        content: `I was in limbo here. Amazingly, I had a job now, but it wasn’t quite right for me and I was antsy about it. I wasn’t producing art, so my old site felt disingenuous. I wanted to show that I was “doing stuff,” but had no way to unify what I was doing. I was honestly embarrassed of my work – this was so harsh on myself, because at the time I had maybe a year of actual design experience, and it was peak-pandemic – so I felt the need to reference projects without actually showing them, all under a sheen of being “multi-disciplinary” (this is a clearly a theme in how my insecurities manifest). I wanted to hide myself…and I also wanted an excuse to learn React.</br></br>
        
        My girlfriend at the time helped me deploy this on Netlify instead of GoDaddy, which at first wasn't working, then when it finally did we screamed.`,
        intention: "Learn React, Create a minimal resume-like feel, Take my work “off the grid” for a bit",
        milestone: "Using React.js, Moved hosting to Netlify (WAY cheaper!)",
        favorite: "The rotating headers on scroll (which I made with a library)"
      },

      {
        version: "Version 8: 2022",
        cover: "./website-archive/v8_cover.webp",
        alt1: "./website-archive/v8_alt-1.webp",
        alt2: "",
        content: `After almost 2 years as a product designer at a small tech agency, I was back on the job hunt with a rigor. Rather than teetering between feeling like an artist vs. a web designer, my design sensibility had grown more informed by artists whose medium is websites.<br/><br/>

        I had a much better understanding of CSS thanks to <a href="https://css-for-js.dev/" target="_blank">Josh Comeau’s CSS for JavaScript Developers</a> and was actually more comfortable writing all my CSS from scratch than using a library. <br/><br/>
        
        This was my website when I got a senior web design job at the New York Times.`,
        intention: "Evolve my design style from graphic and playful to thoughtful and atmospheric, Leverage a better understanding of React for more dynamic rendering and data handling",
        milestone: "Calling a third-party API,Using conditional rendering, Writing all CSS from scratch",
        favorite: "Giving the website a theme song (Ars Nova by Emerald Web)"
      },

      {
        version: "Version 9: 2023",
        cover: "./website-archive/v9_cover.webp",
        alt1: "",
        alt2: "",
        content: `This was a true placeholder site. I've had more of these over the years, but haven't kept track of all of them. I felt a new relief in being able to take myself off the internet after getting a new job.<br/><br/>
        
        My bio on this site opens with "Today Shea..." – I’ve had the domain <span class="highlight">shea.today</span> for a long time (my name rhymes with "today"), and always consider switching my domain from <span class="highlight">sheafitz.com</span>, but there’s something that pulls me back toward keeping the domain I've always had. Something about it feels like trying to rename a stuffed animal from your childhood. <span class="highlight">shea.today</span> will probably be something else one day…`,
        intention: "The “Gone Fishing” of websites",
        milestone: "I didn't need a job...and my website didn’t have to be anything at all",
        favorite: "Music note header that I couldn’t center for the life of me (but I didn’t have to care)"
      },

      {
        version: "Version 10: 2024 (current)",
        cover: "./website-archive/v10_cover.webp",
        alt1: "",
        alt2: "",
        content: `I decided to take my website off of placeholder-mode when I was invited to contribute writing to an online publication and didn't want my writing credit to lead to a dead end. Overall I feel less pressure to categorize myself, and I have the space and time to reevaluate myself as an artist, but I'm less worried about the optics from a hiring perspective. I'm just hanging out on the web.`,
        intention: "Refinement and breathability",
        milestone: "Refactored to use CSS Grid, Image optimization, Fade transitions on page load",
        favorite: 'Custom scrollbar, finally...my favorite part of any website'
      }
  ];
  