export const flowers = [ 
    
    {
        source: './flowers/01.webp'
    },

    {
        source: './flowers/02.webp'
    },

    {
        source: './flowers/03.webp'
    },

    {
        source: './flowers/04.webp'
    },

    {
        source: './flowers/05.webp'
    },

    {
        source: './flowers/06.webp'
    },

    {
        source: './flowers/07.webp'
    },

    {
        source: './flowers/08.webp'
    },
    

];