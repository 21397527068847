export const habit = [ 
    
    {
        source: './habit/01.webp'
    },

    {
        source: './habit/02.webp'
    },

    {
        source: './habit/03.webp'
    },

    {
        source: './habit/04.webp'
    },

    {
        source: './habit/05.webp'
    },

    {
        source: './habit/06.webp'
    },

    {
        source: './habit/07.webp'
    },

    {
        source: './habit/08.webp'
    },

    {
        source: './habit/09.webp'
    },

    {
        source: './habit/010.webp'
    },
];








