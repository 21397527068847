export const writing = [

    {
      title: 'The Pleasure x Effort Matrix',
      link:
        'https://syllabusproject.org/the-pleasure-x-effort-matrix/',
        source: 'Syllabus Project',
        year: '2024'
    },

    {
      title: 'Shea Fitz on sheafitz.com',
      link:
        'https://blog.gossipsweb.net/behind-the-scenes/shea-fitz-on-shea-fitz.html',
        source: "Gossip's Web",
        year: '2021'
    },

    {
        title: 'A playlist that is a poem',
        link:
          "https://store.are.na/products/are-na-annual-2021#:~:text=This%20year's%20Annual%20is%20themed,fiona%20carty%2C%20Juliana%20Castro%2C%20R.C.",
          source: 'Are.na Annual',
          year: '2021'
    },

    {
        title: 'The First Computer Mouse Conference',
        link:
          'https://www.are.na/blog/an-entire-conference-dedicated-to-the-computer-mouse',
        source: 'Are.na Blog',
        year: '2019'
    },

    {
        title: "The Queer Zine Library That's Finding a Global Community",
        link:
          'https://eyeondesign.aiga.org/the-queer-zine-library-in-hong-kong-thats-finding-a-global-community/',
        source: 'AIGA Eye on Design',
        year: '2019'
    },

    {
        title: "RAPAPAWN's Mind-Bending Loops",
        link:
          'https://eyeondesign.aiga.org/rapapawns-mind-bending-loops-blend-early-avant-garde-animation-with-abstract-comics/',
        source: 'AIGA Eye on Design',
        year: '2018'
    },

    {
        title: 'Queer Technologies',
        link:
          'https://www.are.na/blog/queer-technologies',
        source: 'Are.na Blog',
        year: '2018'
    },

    {
        title: 'Breaking the Sequence',
        link:
          'https://www.are.na/blog/breaking-the-sequence',
        source: 'Are.na Blog',
        year: '2017'
    },



  ];